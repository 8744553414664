import React from "react"
import {
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
  PseudoBox,
} from "@chakra-ui/core"
import Layout from "../components/layout"
import {
  SectionHeading,
  Paragraph,
  SubHeading,
  Bold,
  InlineStack,
  Illustration,
} from "../components/ui-elements"
import SEO from "../components/seo"

import img1 from "../images/VS_illus_01-01.png"
import img2 from "../images/VS_icon-01.png"
import img3 from "../images/VS_illus_03-01.png"
import img4 from "../images/VS_illus_04-01.png"
import img5 from "../images/VS_illus_02-01.png"
import {Link as GatsbyLink} from "gatsby";
import VirtualAssistant from "./virtual-assistant"

const WhatIsIpPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Layout>
      <SEO title="What is IP - VERIFiSMART" description="Understanding intellectual property (IP) rights with VerifiSmart - Explore the Importance of Intellectual Property Protection. Learn how our cutting-edge solutions safeguard your intellectual property assets. Discover why businesses trust us for comprehensive IP ownership verification services. Safeguard your creative work and innovations with blockchain-backed certificates. Learn how!"/>
      <Box py="2" color="theme-dark">
        <Box px="4" w="100%" maxW="containers.xl" mx="auto">
          <Box my="16">
            <SectionHeading>What is IP?</SectionHeading>
            <Paragraph>
              In short, Intellectual Property (IP) is the{" "}
              <Bold>protection of creativity and innovation.</Bold>
            </Paragraph>
            <Paragraph>
              In an age of innovation and creativity, scientific advances, tech
              developments, trend-setter designs and new business models are
              radically changing our lives every day. To a large extent, we can
              thank IP rights and the laws that protect them for this continuous
              stream of innovation.
            </Paragraph>
            <InlineStack>
              <Paragraph>
                A person or company could invest significant time and money in
                developing a new idea or pursuing a creative endeavour, thus
                taking a financial risk. To encourage innovation and the sharing
                of new ideas despite these risks, IP law rewards risk-takers
                with <Bold>exclusive rights</Bold> to use and profit from their
                creation for a defined period of time.
              </Paragraph>
              <Illustration src={img1} />
            </InlineStack>
            <Paragraph>
              Most of these rights however are granted on unregistered basis
              which means that the person seeking to enforce them would first
              need to prove that it owns them.
            </Paragraph>
            <Paragraph>
              There are{" "}
              <PseudoBox
                color="theme-purple"
                _hover={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                as={Bold}
                onClick={onOpen}
              >
                various types of IP
              </PseudoBox>{" "}
              that can apply to an idea, creation, product and information and
              it is very important protect them to add value to it and prevent
              potential issues in the future.
            </Paragraph>
            <>
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Types of IP</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Accordion allowMultiple>
                      <AccordionItem>
                        <AccordionHeader>
                          <Box color="theme-green" flex="1" textAlign="left">
                            Trade Marks:
                          </Box>
                          <AccordionIcon />
                        </AccordionHeader>
                        <AccordionPanel pb={4}>
                          <Paragraph fontWeight="200" fontSize="md" my="4">
                            Any logos, names or phrases used in the course of
                            trade to distinguish your goods or services from
                            those of your competitors.
                          </Paragraph>

                          <Paragraph fontWeight="200" fontSize="md" my="4">
                            You can register in the EU or UK intellectual
                            property office. In some countries, such as the UK,
                            you can also claim unregistered rights, but you are{" "}
                            <Bold color="theme-purple">
                              required to prove ownership
                            </Bold>{" "}
                            and use.
                          </Paragraph>
                        </AccordionPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionHeader>
                          <Box color="theme-green" flex="1" textAlign="left">
                            Designs:
                          </Box>
                          <AccordionIcon />
                        </AccordionHeader>
                        <AccordionPanel pb={4}>
                          <Paragraph fontWeight="200" fontSize="md" my="4">
                            Any original designs of the appearance of a purely
                            functional product (i.e. the shape or configuration
                            (whether internal or external) of the whole or part
                            of an article, recorded in a design document)
                          </Paragraph>

                          <Paragraph fontWeight="200" fontSize="md" my="4">
                            You can register in the EU or UK intellectual
                            property office or choose to retain and enforce
                            unregistered rights to save costs and lengthy
                            administrative procedures – in this case{" "}
                            <Bold color="theme-purple">
                              records of designs and proof of ownership will be
                              required.
                            </Bold>
                          </Paragraph>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionHeader>
                          <Box color="theme-green" flex="1" textAlign="left">
                            Copyright:
                          </Box>
                          <AccordionIcon />
                        </AccordionHeader>
                        <AccordionPanel pb={4}>
                          <Paragraph fontWeight="200" fontSize="md" my="4">
                            Protects the authorship of artistic or literary
                            expression. It does not protect ideas but rather the
                            work itself fixed in a tangible hard copy.
                          </Paragraph>

                          <Paragraph fontWeight="200" fontSize="md" my="4">
                            Rights are granted automatically without need for
                            official registration,{" "}
                            <Bold color="theme-purple">
                              but copyright needs to be recorded, claimed and
                              ownership proved in order to protect your work and
                              enforce your rights against third parties.
                            </Bold>
                          </Paragraph>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionHeader>
                          <Box color="theme-green" flex="1" textAlign="left">
                            Know How:
                          </Box>
                          <AccordionIcon />
                        </AccordionHeader>
                        <AccordionPanel pb={4}>
                          <Paragraph fontWeight="200" fontSize="md" my="4">
                            Valuable information about your business operations
                            or procedures not readily available to the public.
                            Highly confidential Know How is also known as a
                            Trade Secret.
                          </Paragraph>

                          <Paragraph fontWeight="200" fontSize="md" my="4">
                            You must{" "}
                            <Bold color="theme-purple">
                              keep records to prove ownership and ensure they
                              are kept as confidential as possible to enforce
                              your rights against third-parties.
                            </Bold>{" "}
                            When sharing with another party it is important to
                            do it under an agreement of confidentiality.
                          </Paragraph>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionHeader>
                          <Box color="theme-green" flex="1" textAlign="left">
                            Databases:
                          </Box>
                          <AccordionIcon />
                        </AccordionHeader>
                        <AccordionPanel pb={4}>
                          <Paragraph fontWeight="200" fontSize="md" my="4">
                            Any compilation of information which is utilised for
                            financial gain and in an autonomous manner, where
                            there has been qualitatively and/or quantitatively a
                            substantial investment in obtaining, verifying, or
                            presenting the contents. If contents are arranged in
                            a creative way or subject to a specific criteria,
                            they can also be protected under copyright.
                          </Paragraph>

                          <Paragraph fontWeight="200" fontSize="md" my="4">
                            Rights are granted automatically without need for
                            official registration, but{" "}
                            <Bold color="theme-purple">
                              compilations need to be recorded, claimed and
                              ownership proved in order to protect your work and
                              enforce your rights against third parties.
                            </Bold>
                          </Paragraph>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionHeader>
                          <Box color="theme-green" flex="1" textAlign="left">
                            Patents:
                          </Box>
                          <AccordionIcon />
                        </AccordionHeader>
                        <AccordionPanel pb={4}>
                          <Paragraph fontWeight="200" fontSize="md" my="4">
                            Protects inventions, the process of how something
                            works or the method of making it.
                          </Paragraph>

                          <Paragraph fontWeight="200" fontSize="md" my="4">
                            You must register in the intellectual property
                            offices of the countries where you seek protection
                            in order to enforce patent rights against third
                            parties.
                          </Paragraph>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </ModalBody>

                  <ModalFooter></ModalFooter>
                </ModalContent>
              </Modal>
            </>
            <Paragraph>
              Recording, registration and protection gives you exclusive rights
              to exploit your intellectual property assets and allows you to
              stop others from using them without your permission.
            </Paragraph>
          </Box>
          <Box my="16">
            <SectionHeading>The issue</SectionHeading>
            {/* <Flex
              my="8"
              flexDir={["column", "column", "row"]}
              justify={["flex-start", "flex-start", "space-between"]}
              align={"center"}
            > */}
            <InlineStack>
              <Illustration h="250px" my="4" mx="8" src={img2} />
              <Box>
                <Paragraph>
                  The main rule to remember for the protection of IP rights is
                  <Bold> “first in time, first in right”</Bold>
                </Paragraph>

                <Paragraph>
                  If your creation is used without your permission and you want
                  to take action against the infringer, you will have to prove
                  that the work was created by you and the date and even
                  possibly the time on which it was created.
                </Paragraph>
              </Box>
            </InlineStack>
            {/* </Flex> */}
            <Paragraph>
              For unregistered rights such as copyright, databases,
              trade-secrets and designs it is therefore very important to keep a
              record of their development from start to end and accurately
              document any final version on which you would wish to claim and
              enforce rights.
            </Paragraph>
            <SubHeading color="theme-purple">
              Registration is the quickest and easiest way to prove ownership!
            </SubHeading>
            <Paragraph>
              Many individuals resort to simple “solutions” such as keeping a
              home-made record stored in their computer, emailing their work to
              themselves or even posting it on their website or social media
              account, as a way of recording the such date and time.
            </Paragraph>
            <Paragraph>
              Alternatively, professional artists or companies may use the
              services of a law firm to certify the document on the date that it
              was presented to them.
            </Paragraph>
            <Paragraph>
              All of the above methods pose{" "}
              <Bold color="theme-purple">two significant risks</Bold> when it
              comes to proving ownership:
            </Paragraph>
            <InlineStack>
              <Illustration src={img3} />
              <Paragraph>
                <Bold color="theme-purple">CONFIDENTIALITY</Bold> – Publishing
                your work without prior protection or transferring it to any
                third-party unencrypted may risk any third-parties being able to
                access it, leaking it or use it without your authorisation.{" "}
              </Paragraph>
            </InlineStack>
            <InlineStack>
              <Paragraph>
                <Bold color="theme-purple">CHALLEANGEABLE</Bold> - Now a days is
                very easy to alter records or create false records that could
                potentially show dates and times that are not real or accurate.
                Most forms of recording and certification are therefore exposed
                to the risk of being challenged by infringers in order to avoid
                liability for their actions.
              </Paragraph>
              <Illustration src={img4} />
            </InlineStack>
          </Box>
          <Box my="16">
            <SubHeading color="theme-purple">
              VERIFiSMART takes care of this problem!
            </SubHeading>
            <Paragraph>
              VERIFiSMART is based on a zero-knowledge proof framework of
              blockchain technology that completely guarantees the
              inalterability, security and traceability of your work.
            </Paragraph>
            <InlineStack>
              <Box>
                <Paragraph my="2" lineHeight="2">
                  When you record your work in our platform, we will
                  automatically encrypt it so that only you can access and keep
                  it completely confidential until you are ready to release it
                  to the public.
                </Paragraph>
                <Paragraph>
                  When you register your work in our platform it is printed in
                  the blockchain, hashed and time stamped. You will receive a
                  certificate that can be used as an unchallengeable proof of
                  the ownership, date and time of creation of your work.
                </Paragraph>
              </Box>
              <Illustration src={img5} />
            </InlineStack>
            <Paragraph>
              With VERIFiSMART you can create a secured digital trail of records
              of the evolution of your projects and obtain a certificate to
              prove ownership of your work.
            </Paragraph>
            <Paragraph my="2">
              Once your work is recorded on the blockchain, it’s there for an
              unlimited period of time. Any one can verify the existence of a
              registration without accessing any further information other than
              the owner’s name, and time stamp of such registration.
            </Paragraph>
            <Paragraph>
              Stay updated with the latest industry insights and news by visiting our{" "}
              <Bold
                  textDecoration="underline"
                  to="/blog"
                  as={GatsbyLink}
              >Blog
              </Bold> page.
            </Paragraph>
          </Box>
        </Box>
      </Box>
      <VirtualAssistant />
    </Layout>
  )
}

export default WhatIsIpPage
